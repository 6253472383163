/*!

=========================================================
* Олександр Закаржевський - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { memo, useState } from 'react';
import Text from '../../common/Text';

// reactstrap components

// core components

const API_MAIL = 'https://www.azex.podorva.com/send-email'

const PERSONAL = [
    {
        id: '000-person',
        name: 'Закаржевський Олександр',
        position: 'Інженер проектувальник, ГІП.',
        text: 'Почав свій будівельний шлях в 2011р. з інженера-конструктора, поєднуючи проектування як промислових будівель та споруд, так і житлових будинків. Допоміг замовникам отримати більше сотні будівельних паспортів та дозвільних документів. Проходження експертизи, ведення авторського нагляду, розрахунки конструкцій.'

    },
    // {
    //     id: '001-person',
    //     name: 'Федченко Михайло',
    //     position: 'Архітектор, ГАП.',
    //     text: 'Досвідчений спеціаліст з великою кількістю реалізованих об\'єктів заплечима якв Україні, так і за її межами. Планування приміщень, меблювання, інсоляційний розрахунок, об\'ємна візуалізація проектованого об\'єкту та інше.'
    // },
    {
        id: '002-person',
        name: 'Горковенко Дмитро',
        position: 'Інженер проектувальник електричних мереж з досвідом більше 15-ти років.',
        text: 'Справжній фахівець, відповідальний до свого діла. Проекти освітлення, електрофікації, блискавкозахисту та інше.'
    },
]

function ContactsPage() {
    const [isSending, setIsSending] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        const { emailInput, phoneInput, nameInput, lastnameInput, messageInput } = e.target;
        const data = {
            name: nameInput.value,
            surname: lastnameInput.value,
            email: emailInput.value,
            message: messageInput.value,
            phone: phoneInput.value,
        }
        if (!data.name || !data.email || !data.message) {
            return alert('Будь ласка, заповніть обвʼязкові поля!');
        }
        setIsSending(true);
        fetch(API_MAIL, {
            method: 'POST', headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            }, body: JSON.stringify(data),
        }).then((res) => {
            if (res) {
                alert('Повідомлення надіслано. Ми звʼяжемося з вами в найближчий час')
                nameInput.value = '';
                lastnameInput.value = '';
                emailInput.value = '';
                messageInput.value = '';
                phoneInput.value = '';
            }
        }).catch((error) => {
            alert('Повідомлення не надіслано. Сталася помилка')
        }).finally(() => {
            setIsSending(false)
        })
    }

    return (
        <div className={'contacts'}>
            <div className={'contacts-container'}>
                <div className={'contacts-article'}>
                    <Text
                        isSmall
                        type={'article'}
                        text={'Професійний підхід до справи, розробка проектів будь-якої складності, величезний творчий\n' +
                            '                    потенціал -\n' +
                            '                    це те, за що нас цінують клієнти. Ми розробляємо унікальні проекти, гарантуємо високу якість та\n' +
                            '                    дотримуємося термінів. З нашою допомогою Ви можете реалізувати свою мрію!'}>
                    </Text>
                    <div className={'contacts-list'}>
                        <a className={'link'} href={'tel:+380937532319'}>
                            <span className={'left'}>Телефон: </span>
                            <span>+38 093 753 23 19</span>
                        </a>
                        <div className={'socials-list'}>
                            <a className={'link icon'} href={'https://t.me/Azengin'}>
                                <i className="fa fa-telegram"/>
                            </a>
                            {/*<a className={'link icon'} href={'#'}>*/}
                            {/*    <i className="fa fa-facebook-square"/>*/}
                            {/*</a>*/}
                            {/*<a className={'link icon'} href={'#'}>*/}
                            {/*    <i className="fa fa-instagram"/>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
                <form id={'form'} onSubmit={onSubmit}>
                    <div className={'form-group'}>
                        <div className={'input-container'}>
                            <label htmlFor={'nameInput'}>Ваше ім`я</label>
                            <input id={'nameInput'}></input>
                        </div>
                        <div className={'input-container'}>
                            <label htmlFor={'lastnameInput'}>Прізвище</label>
                            <input id={'lastnameInput'}></input>
                        </div>
                    </div>
                    <div className={'form-group'}>
                        <div className={'input-container'}>
                            <label htmlFor={'emailInput'}>Email</label>
                            <input id={'emailInput'} type={'email'}></input>
                        </div>
                        <div className={'input-container'}>
                            <label htmlFor={'phoneInput'}>Телефон</label>
                            <input id={'phoneInput'}></input>
                        </div>
                    </div>
                    <div className={'form-group'}>
                        <div className={'message-container'}>
                            <label htmlFor={'messageInput'}>Повідомлення </label>
                            <textarea id={'messageInput'}></textarea>
                        </div>
                    </div>
                    <button
                        disabled={isSending}
                        id={'submit'} className={'button'}>Надіслати
                    </button>
                </form>
            </div>
            <div className={'personal-container'}>
                {
                    PERSONAL.map((man) => {
                        return (
                            <div className={'man'} key={man.id}>
                                <Text highlight={man.name} isSmall />
                                <Text text={man.position} isSmall />
                                <Text text={man.text} isSmall />
                            </div>
                        )
                    })
                }
            </div>
            <Text text={'ФОП Закаржевський О.І.'} isSmall />
            <Text text={'ЄДРПОУ: 3282100552'} isSmall />
        </div>
    );
}

export default memo(ContactsPage);
