const MAIN_TITLE = {
    main: 'AZEXPROJECT',
    subtitle: 'Проектування в Житомирській області та по всій Україні'
}

const LOGO = require('../../assets/AzexLogo.png');

const MainTitle = () => {
    return (
        <div className={'main-title'}>
            {/*<h1>{MAIN_TITLE.main}</h1>*/}
            <img className={'main-logo'} alt={MAIN_TITLE.main} src={LOGO}></img>
            <h2>{MAIN_TITLE.subtitle}</h2>
        </div>
    )
}

export default MainTitle;
