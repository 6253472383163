import Fade from '../../../common/Fade';
import { useEffect, useState } from 'react';

const ServicesCard = ({ isVisible, index, isActive, onClick, text, highlight, imageSrc, isFullScreen }) => {
    const [isCardVisible, setIsCardVisible] = useState(false);
    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setIsCardVisible(true);
            }, 200 * (index + 1));
        }
    }, [isVisible]);
    return (
        <div className={`services-card ${isFullScreen ? 'full-screen' : ''} ${isCardVisible ? 'visible' : ''}`}
             onClick={onClick}>
            <img alt={'Послуга проектної компанії'} src={imageSrc} className={'services-card-image'}></img>
            <h4 className={`services-card-title ${isActive ? 'white' : ''}`}>{highlight}</h4>
            <p className={'services-card-description'}>{text}</p>
            <Fade isDark={!isActive}/>
        </div>
    );
}

export default ServicesCard;
