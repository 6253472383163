import Header from '../../header/Header';
import MainPage from '../../pages/MainPage';
import GreetingsPage from '../../pages/GreetingsPage';
import WhyPage from '../../pages/WhyPage';
import ServicesPage from '../../pages/services/ServicesPage';
import ContactsPage from '../../pages/contacts/ContactsPage';
import React from 'react';
import PlansPage from '../../pages/plans/PlansPage';

const MainScreen = ({
                        isHeaderCollapsed,
                        parallaxOffset,
                        itemsInView,
                        onScrollToContacts,
                        onScrollToPrices,
                    }) => {
    return (
        <main>
            <Header isCollapsed={isHeaderCollapsed} onScrollToContacts={onScrollToContacts} onScrollToPrices={onScrollToPrices}/>
            <MainPage offset={parallaxOffset}/>
            <GreetingsPage isCountersVisible={itemsInView.counters} offset={parallaxOffset} onScrollToContacts={onScrollToContacts}/>
            <WhyPage isVisible={itemsInView.why}/>
            <PlansPage />
            <ServicesPage isVisible={itemsInView.services}/>
            <ContactsPage/>
        </main>
    );
}

export default MainScreen;
